import React from 'react';

import { TypographyH3 } from '@smidyo/smidyo-reactlib-ui-atoms/atoms/typography';
import { styledWithTheme } from '@smidyo/smidyo-reactlib-ui-atoms/style/theme';

const Text = styledWithTheme.p`
	max-width: 35rem;
	text-align: center;
	margin-block-end: 3rem;
	line-height: 1.5;
`;

const Link = styledWithTheme.a`
	color: ${(p) => p.theme.palette.brandColor};
`;

export const WelcomeText = () => {
	return (
		<>
			<TypographyH3>Frontend for Free Vector Conversion API</TypographyH3>
			<Text>
				You can use this frontend to use our vector conversion API. For
				more information, please go to our{' '}
				<Link href="https://smidyo.com/vector-express/">main page</Link>
				. You can find docs on{' '}
				<Link href="https://github.com/smidyo/vectorexpress-api">
					GitHub
				</Link>
				.
			</Text>
		</>
	);
};
