/* Copyright 2020 Ricardo Iván Vieitez Parra
 *
 * Permission to use, copy, modify, and/or distribute this software for any
 * purpose with or without fee is hereby granted, provided that the above
 * copyright notice and this permission notice appear in all copies.
 *
 * THE SOFTWARE IS PROVIDED "AS IS" AND THE AUTHOR DISCLAIMS ALL WARRANTIES WITH
 * REGARD TO THIS SOFTWARE INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY
 * AND FITNESS. IN NO EVENT SHALL THE AUTHOR BE LIABLE FOR ANY SPECIAL, DIRECT,
 * INDIRECT, OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING FROM
 * LOSS OF USE, DATA OR PROFITS, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR
 * OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR
 * PERFORMANCE OF THIS SOFTWARE.
 */

import React from 'react';

import { ApiV2Converter } from './components/ApiV2Converter';
import { ErrorBoundary } from './components/ErrorBoundary';
import { styledWithTheme } from '@smidyo/smidyo-reactlib-ui-atoms/style/theme';

const Wrapper = styledWithTheme.div`
	min-height: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	background-color: white;
	padding-right: 1rem;
	padding-left: 1rem;
`;

const Logo = styledWithTheme.a`
	margin-top: 3rem; 
	margin-bottom: 2rem; 
	place-self: center;
	& img {
		width: 20rem;
	}
`;

const Footer = styledWithTheme.a`
	color: ${(p) => p.theme.palette.text.medium};
	margin-bottom: 1rem;
	text-align: center;
	text-decoration: none;
`;

export function App(): JSX.Element {
	return (
		<Wrapper>
			<ErrorBoundary>
				<Logo href="https://smidyo.com/vector-express/">
					<img src="/logo.svg" />
				</Logo>
				<ApiV2Converter />
				<Footer href="https://smidyo.com">© Smidyo Ltd.</Footer>
			</ErrorBoundary>
		</Wrapper>
	);
}
