/* Copyright 2020 Ricardo Iván Vieitez Parra
 *
 * Permission to use, copy, modify, and/or distribute this software for any
 * purpose with or without fee is hereby granted, provided that the above
 * copyright notice and this permission notice appear in all copies.
 *
 * THE SOFTWARE IS PROVIDED "AS IS" AND THE AUTHOR DISCLAIMS ALL WARRANTIES WITH
 * REGARD TO THIS SOFTWARE INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY
 * AND FITNESS. IN NO EVENT SHALL THE AUTHOR BE LIABLE FOR ANY SPECIAL, DIRECT,
 * INDIRECT, OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING FROM
 * LOSS OF USE, DATA OR PROFITS, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR
 * OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR
 * PERFORMANCE OF THIS SOFTWARE.
 */

import React from 'react';
import { Button } from '@smidyo/smidyo-reactlib-ui-atoms/atoms/button';
import { styledWithTheme } from '@smidyo/smidyo-reactlib-ui-atoms/style/theme';

const Container = styledWithTheme.div`
	margin-bottom: 1rem;
`;

const FormatButton = styledWithTheme(Button)`
	min-height: 1.5rem;
`;

interface InputFormatProps {
	formats: string[];
	onChange?: (format: string) => void;
	value?: string;
}

interface InputFormatState {
	selected?: string;
}

export class InputFormat extends React.Component<
	InputFormatProps,
	InputFormatState
> {
	constructor(props: InputFormatProps) {
		super(props);
		this.state = {};
	}

	static getDerivedStateFromProps(
		props: InputFormatProps,
	): InputFormatState {
		return {
			selected: props.value,
		};
	}

	setSelection(format: string): void {
		if (this.state.selected === format) {
			return;
		}

		this.setState({
			...this.state,
			selected: format,
		});

		if (this.props.onChange) {
			this.props.onChange(format);
		}
	}

	render(): React.ReactNode {
		return (
			<Container>
				{this.props.formats.map((format) => {
					return (
						<FormatButton
							size="small"
							key={format}
							onClick={() => this.setSelection(format)}
							variant={
								this.state.selected === format
									? 'outline'
									: 'normal'
							}
							disabled={
								!!this.props.value &&
								this.state.selected !== format
							}
						>
							{format}
						</FormatButton>
					);
				})}
			</Container>
		);
	}
}
