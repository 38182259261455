/* Copyright 2020 Ricardo Iván Vieitez Parra
 *
 * Permission to use, copy, modify, and/or distribute this software for any
 * purpose with or without fee is hereby granted, provided that the above
 * copyright notice and this permission notice appear in all copies.
 *
 * THE SOFTWARE IS PROVIDED "AS IS" AND THE AUTHOR DISCLAIMS ALL WARRANTIES WITH
 * REGARD TO THIS SOFTWARE INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY
 * AND FITNESS. IN NO EVENT SHALL THE AUTHOR BE LIABLE FOR ANY SPECIAL, DIRECT,
 * INDIRECT, OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING FROM
 * LOSS OF USE, DATA OR PROFITS, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR
 * OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR
 * PERFORMANCE OF THIS SOFTWARE.
 */

import 'core-js/features/array/from';
import 'core-js/features/set';

import 'whatwg-fetch';

import React from 'react';
import { LoadingAnimation } from '@smidyo/smidyo-reactlib-ui-atoms/atoms/loading';

import { ConvertModal } from './ConvertModal';
import { Modal } from '@smidyo/smidyo-reactlib-ui-atoms/atoms/modal';
import { Button } from '@smidyo/smidyo-reactlib-ui-atoms/atoms/button';
import { styledWithTheme } from '@smidyo/smidyo-reactlib-ui-atoms/style/theme';
import { FileZone } from './FileZone';
import { WelcomeText } from './WelcomeText';
import { InputFormat } from './InputFormat';
import { OutputFormat } from './OutputFormat';

type ApiV2ConverterProps = {};

enum ApiV2ConverterStatus {
	loading,
	ready,
	error,
	selectionError,
	selectionPermanentError,
}

interface ApiV2ConverterState {
	status: ApiV2ConverterStatus;
	inputFormats: string[];
	outputFormats: string[];
	alternatives: { [key: string]: string[] | null };
	error?: string;

	file?: File;
	inputFormat?: string;
	outputFormat?: string;
	alternative?: string;
}

const Container = styledWithTheme.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem 0 2rem 0;
	position: relative;
`;

const Hackerman = styledWithTheme.img`
	position: absolute;
	right: 2rem;
	bottom: 2rem;
	width: 8rem;
	@media(max-width: ${(p) => p.theme.responsiveBreakpoints.small}px) {
		display: none;
	}
	pointer-events: none;
`

export class ApiV2Converter extends React.Component<
	ApiV2ConverterProps,
	ApiV2ConverterState
> {
	constructor(props: ApiV2ConverterProps) {
		super(props);

		this.state = {
			status: ApiV2ConverterStatus.loading,
			inputFormats: [],
			outputFormats: [],
			alternatives: {},
		};
	}

	static getAlternativeKey(state: ApiV2ConverterState): string | undefined {
		return state.inputFormat && state.outputFormat
			? `${encodeURIComponent(
					state.inputFormat,
			  )}/auto/${encodeURIComponent(state.outputFormat)}`
			: undefined;
	}

	componentDidMount(): void {
		this.getFormats();
	}

	getFormats(): void {
		fetch(`/api/v2/public/convert`)
			.then((response) => {
				if (response.status === 200) {
					return response.json();
				} else if (response.status === 429) {
					throw new Error(
						`Too many requests. Please try again later.`,
					);
				} else {
					throw new Error(
						`Unexpected response status: ${
							response.status || '(none)'
						}`,
					);
				}
			})
			.then((data) => {
				if (
					typeof data !== 'object' ||
					!('converters' in data) ||
					!Array.isArray(data['converters'])
				) {
					throw new Error('Invalid response format');
				}

				const formats = (formatsKey: string): string[] =>
					Array.from(
						new Set<string>(
							data['converters']
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								.filter((converter: any) => {
									return (
										typeof converter === 'object' &&
										formatsKey in converter &&
										Array.isArray(converter[formatsKey]) &&
										converter[formatsKey].reduce(
											// eslint-disable-next-line @typescript-eslint/no-explicit-any
											(acc: boolean, v: any) =>
												acc &&
												Object(v) instanceof String,
											true,
										)
									);
								})
								.flatMap(
									(converter: { [key: string]: string }) => {
										return converter[formatsKey];
									},
								),
						),
					).sort();

				this.setState({
					status: ApiV2ConverterStatus.ready,
					inputFormats: formats('inputFormats'),
					outputFormats: formats('outputFormats'),
				});
			})
			.catch((e: Error) => {
				this.setState({
					status: ApiV2ConverterStatus.error,
					error: e.message,
					inputFormats: [],
					outputFormats: [],
				});
			});
	}

	setFile(file: File): void {
		const extension = (
			(file.name.match(/\.([^.]+)$/) || [])[1] || ''
		).toLowerCase();

		if (this.state.inputFormats.indexOf(extension) === -1) {
			return;
		}

		this.setState({
			file: file,
			inputFormat: extension,
		});
	}

	setInputFormat(format: string): void {
		if (format !== this.state.inputFormat) {
			this.setState(
				{
					...this.state,
					inputFormat: format,
					alternative: ApiV2Converter.getAlternativeKey({
						...this.state,
						inputFormat: format,
					}),
					status: ApiV2ConverterStatus.ready,
				},
				() => this.getConversionPaths(),
			);
		}
	}

	setOutputFormat(format?: string): void {
		if (format) {
			if (format !== this.state.outputFormat) {
				this.setState(
					{
						...this.state,
						outputFormat: format,
						alternative: ApiV2Converter.getAlternativeKey({
							...this.state,
							outputFormat: format,
						}),
						status: ApiV2ConverterStatus.ready,
					},
					() => this.getConversionPaths(),
				);
			}
			return;
		}
		this.setState({
			...this.state,
			outputFormat: undefined,
			alternative: undefined,
			status: ApiV2ConverterStatus.ready,
		});
	}

	getConversionPaths(): void {
		if (
			!this.state.alternative ||
			[
				ApiV2ConverterStatus.ready,
				ApiV2ConverterStatus.selectionError,
				ApiV2ConverterStatus.selectionPermanentError,
			].indexOf(this.state.status) === -1
		) {
			return;
		}

		class PermanentError extends Error {}

		const alternative = this.state.alternative;

		if (!this.state.alternatives || !this.state.alternatives[alternative]) {
			fetch(`/api/v2/public/convert/${alternative}`)
				.then((response) => {
					if (response.status === 200) {
						return response.json();
					} else if (response.status === 429) {
						throw new Error(
							`Too many requests. Please try again later.`,
						);
					} else if (response.status === 404) {
						throw new PermanentError(
							`Unsupported input / output combination`,
						);
					} else {
						throw new Error(
							`Unexpected response status: ${
								response.status || '(none)'
							}`,
						);
					}
				})
				.then((data) => {
					if (
						typeof data !== 'object' ||
						!('alternatives' in data) ||
						!Array.isArray(data['alternatives'])
					) {
						throw new Error('Unsupported response schema');
					}

					return data['alternatives']
						.filter((alternative) => {
							return (
								typeof alternative === 'object' &&
								'path' in alternative &&
								Object(alternative['path']) instanceof String
							);
						})
						.map(
							(alternative: { path: string }) =>
								alternative['path'],
						);
				})
				.then((alternatives: string[]) => {
					this.setState({
						...this.state,
						status:
							this.state.alternative === alternative
								? ApiV2ConverterStatus.ready
								: this.state.status,
						error:
							this.state.alternative === alternative
								? undefined
								: this.state.error,
						alternatives: {
							...this.state.alternatives,
							[alternative]: alternatives,
						},
					});
				})
				.catch((error: Error) => {
					if (this.state.alternative === alternative) {
						this.setState({
							...this.state,
							status:
								error instanceof PermanentError
									? ApiV2ConverterStatus.selectionPermanentError
									: ApiV2ConverterStatus.selectionError,
							error: error.message,
							alternatives: {
								...this.state.alternatives,
								[alternative]: null,
							},
						});
					}
				});
		}
	}

	render(): React.ReactNode {
		return (
			<>
				{this.state.file &&
					this.state.inputFormat &&
					this.state.outputFormat && (
						<ConvertModal
							file={this.state.file}
							alternative={this.state.alternative}
							alternatives={this.state.alternatives}
							onClose={() => this.setOutputFormat()}
						/>
					)}
				{this.state.status === ApiV2ConverterStatus.error && (
					<Modal>
						<p>{this.state.error}</p>
						<Button onClick={(): void => this.getFormats()}>
							Try again
						</Button>
					</Modal>
				)}
				{this.state.status ===
					ApiV2ConverterStatus.selectionPermanentError && (
					<Modal>
						<span>{this.state.error}</span>
					</Modal>
				)}
				{this.state.status === ApiV2ConverterStatus.selectionError && (
					<div>
						<span>
							{this.state.error}
							<Button
								onClick={(): void => this.getConversionPaths()}
							>
								Try again!
							</Button>
						</span>
					</div>
				)}

				<Container>
					<WelcomeText />
					<FileZone
						formats={this.state.inputFormats}
						file={this.state.file}
						onFile={(f) => {
							this.setFile(f);
						}}
					/>
					<p>You can convert from these formats:</p>
					<InputFormat
						formats={this.state.inputFormats}
						value={this.state.inputFormat}
						onChange={(format) => this.setInputFormat(format)}
					/>
					{this.state.file && (
						<>
							<p>To these formats:</p>
							<OutputFormat
								formats={this.state.outputFormats}
								onSelect={(format) =>
									this.setOutputFormat(format)
								}
							/>
						</>
					)}
					{this.state.status === ApiV2ConverterStatus.loading && (
						<LoadingAnimation size="small" />
					)}
					<Hackerman src="/hackerman_black.png" />
				</Container>
			</>
		);
	}
}
