import React from 'react';
import ReactDOM from 'react-dom';
import {
	globalStyleFactory,
	themeFactory,
} from '@smidyo/smidyo-reactlib-ui-atoms/style/theme';
import '@smidyo/smidyo-icon-font/lib/smidyo-iconfont.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactGA from 'react-ga';

import { ThemeProvider } from 'styled-components';
import * as serviceWorker from './serviceWorker';
import { App } from './App';

import './index.css';

Sentry.init({
	dsn:
		'https://eff6d928f2b44fea887427aeec5683b3@o298390.ingest.sentry.io/5439771',
	integrations: [new Integrations.BrowserTracing()],
	tracesSampleRate: 1.0,
});

ReactGA.initialize('UA-106898961-2');
ReactGA.pageview(window.location.pathname + window.location.search);

export const defaultTheme = themeFactory({
	brandColor: '#1D3BBD',
});

const GlobalStyle = globalStyleFactory(defaultTheme);

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={defaultTheme}>
			<GlobalStyle />
			<App />
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
