import React from 'react';
import { Button } from '@smidyo/smidyo-reactlib-ui-atoms/atoms/button';
import { styledWithTheme } from '@smidyo/smidyo-reactlib-ui-atoms/style/theme';

const Container = styledWithTheme.div`
`;

const FormatButton = styledWithTheme(Button)`
	margin: 0.3rem;
`;

interface OutputFormatProps {
	formats: string[];
	onSelect: (format: string) => void;
}

export const OutputFormat = (props: OutputFormatProps) => {
	return (
		<Container>
			{props.formats.map((format) => {
				return (
					<FormatButton
						size="medium"
						key={format}
						onClick={() => props.onSelect(format)}
						variant="fill"
						color="brand"
						icon="download"
					>
						{format}
					</FormatButton>
				);
			})}
		</Container>
	);
};
