/* Copyright 2020 Ricardo Iván Vieitez Parra
 *
 * Permission to use, copy, modify, and/or distribute this software for any
 * purpose with or without fee is hereby granted, provided that the above
 * copyright notice and this permission notice appear in all copies.
 *
 * THE SOFTWARE IS PROVIDED "AS IS" AND THE AUTHOR DISCLAIMS ALL WARRANTIES WITH
 * REGARD TO THIS SOFTWARE INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY
 * AND FITNESS. IN NO EVENT SHALL THE AUTHOR BE LIABLE FOR ANY SPECIAL, DIRECT,
 * INDIRECT, OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING FROM
 * LOSS OF USE, DATA OR PROFITS, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR
 * OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR
 * PERFORMANCE OF THIS SOFTWARE.
 */

import 'core-js/features/number/is-finite';
import 'whatwg-fetch';

import React from 'react';
import { Button } from '@smidyo/smidyo-reactlib-ui-atoms/atoms/button';
import { Modal } from '@smidyo/smidyo-reactlib-ui-atoms/atoms/modal';
import { LoadingAnimation } from '@smidyo/smidyo-reactlib-ui-atoms/atoms/loading';
import { styledWithTheme } from '@smidyo/smidyo-reactlib-ui-atoms/style/theme';
import {
	TypographyH5,
	TypographySlabSmall,
} from '@smidyo/smidyo-reactlib-ui-atoms/atoms/typography';

const StyledModal = styledWithTheme(Modal)`
	min-height: 15rem;
	justify-content: center;
	align-items: center;
	text-align: center;
	@media(min-width: ${(p) => p.theme.responsiveBreakpoints.small}px) {
		max-width: 20rem;
	}
`;

const Header = styledWithTheme(TypographyH5)`
	margin-bottom: 1rem;
`;

const DownloadLink = styledWithTheme.a`
	text-decoration: none;
	margin-bottom: 1rem;
`;

const ConvertPath = styledWithTheme(TypographySlabSmall)`
	color: ${(p) => p.theme.palette.text.light};
	line-break: anywhere;
	max-width: 16rem;
	margin-bottom: 1rem;
`;

interface ConvertModalProps {
	alternatives: { [key: string]: string[] | null };
	file?: File;
	alternative?: string;
	onClose: () => void;
}

enum ConvertModalStatus {
	ready,
	pending,
	done,
	error,
}

interface ConvertModalState {
	status: ConvertModalStatus;
	error?: string;
	alternativeNumber?: number;
	serverInputFileName?: string;
	resultUrl?: string;
	resultAlternative?: string;
}

export class ConvertModal extends React.PureComponent<
	ConvertModalProps,
	ConvertModalState
> {
	constructor(props: ConvertModalProps, state: ConvertModalState) {
		super(props, state);
		this.state = { status: ConvertModalStatus.ready };
	}

	componentDidUpdate(prevProps: ConvertModalProps): void {
		if (
			prevProps.file !== this.props.file ||
			prevProps.alternative !== this.props.alternative
		) {
			this.setState({
				status: ConvertModalStatus.ready,
				error: undefined,
				alternativeNumber: undefined,
				serverInputFileName: undefined,
				resultUrl: undefined,
			});
		}
	}

	async convert(retry?: boolean): Promise<void> {
		if (
			!this.props.alternative ||
			!(this.props.alternative in this.props.alternatives) ||
			!this.props.file
		) {
			return;
		}

		const alternativeNumber =
			(this.state.alternativeNumber ?? 0) - (retry ? 1 : 0);

		const paths = this.props.alternatives[this.props.alternative];

		if (
			!Number.isFinite(alternativeNumber) ||
			!paths ||
			!paths[alternativeNumber]
		) {
			return;
		}

		const doConversion = (file: File | null): void => {
			console.log(this.props.file!.type);
			fetch(
				paths[alternativeNumber] +
					(this.state.serverInputFileName
						? `?use-file=${encodeURIComponent(
								this.state.serverInputFileName,
						  )}`
						: ''),
				{
					method: 'POST',
					redirect: 'manual',
					body: this.state.serverInputFileName ? undefined : file,
					headers: {
						'Content-Type': this.props.file!.type,
					},
				},
			)
				.then((response) => {
					if (response.status === 201) {
						return response.json();
					} else if (response.status === 429) {
						throw new Error(
							`Too many requests. Please try again later.`,
						);
					} else if (response.status === 500) {
						throw new Error(`An error occurred during conversion`);
					} else {
						throw new Error(
							`Unexpected response status: ${
								response.status || '(none)'
							}`,
						);
					}
				})
				.then((result) => {
					if (result) {
						const serverInputFileName =
							'inputUrl' in result &&
							result['inputUrl'] &&
							Object(result['inputUrl']) instanceof String
								? result['inputUrl'].split('/').slice(-1)
								: undefined;
						const resultUrl =
							'resultUrl' in result &&
							result['resultUrl'] &&
							Object(result['resultUrl']) instanceof String
								? result['resultUrl']
								: undefined;

						if (!serverInputFileName || !resultUrl) {
							throw new Error('Unsupported response schema');
						}

						this.setState({
							...this.state,
							status: ConvertModalStatus.done,
							alternativeNumber: alternativeNumber + 1,
							serverInputFileName: serverInputFileName,
							resultUrl: resultUrl,
							resultAlternative: paths[alternativeNumber]
								.split('convert/')
								.pop(),
						});
					}
				})
				.catch((error: Error) => {
					this.setState({
						...this.state,
						error: error.message,
						status: ConvertModalStatus.error,
						alternativeNumber: alternativeNumber + 1,
						resultUrl: undefined,
					});
				});
		};

		if (!this.state.serverInputFileName) {
			doConversion(this.props.file);
		} else {
			doConversion(null);
		}

		this.setState({ ...this.state, status: ConvertModalStatus.pending });

		return;
	}

	render(): React.ReactNode {
		if (!this.props.file || !this.props.alternative) {
			return null;
		}

		return (
			<StyledModal
				closeButton={
					this.state.status !== ConvertModalStatus.pending
						? { onClickCloseButton: () => this.props.onClose() }
						: undefined
				}
				onClickBackground={() =>
					this.state.status !== ConvertModalStatus.pending &&
					this.props.onClose()
				}
			>
				{this.state.status === ConvertModalStatus.ready &&
				this.state.alternativeNumber === undefined ? (
					<>
						<Header>Ready to go</Header>
						<p>You're about to convert {this.props.file?.name}.</p>
						<Button
							disabled={
								!this.props.alternative ||
								!this.props.alternatives[
									this.props.alternative
								] ||
								!this.props.file
							}
							onClick={() => this.convert()}
							variant="fill"
							color="brand"
							size="medium"
							icon="arrow-right"
						>
							Convert
						</Button>
					</>
				) : this.state.status === ConvertModalStatus.pending ? (
					<>
						<Header>Converting...</Header>
						<LoadingAnimation size="medium" />
					</>
				) : this.state.status === ConvertModalStatus.done &&
				  this.state.resultUrl &&
				  this.props.alternative ? (
					<>
						<Header>Done!</Header>
						<p>Check out your newly converted file.</p>
						<ConvertPath>
							{this.state.resultAlternative}
						</ConvertPath>
						<DownloadLink
							href={this.state.resultUrl}
							download={true}
							target="_blank"
							rel="noopener noreferrer"
						>
							<Button
								variant="fill"
								color="brand"
								icon="download"
								size="medium"
							>
								Download
							</Button>
						</DownloadLink>
						{(this.props.alternatives[this.props.alternative] || [])
							.length > Number(this.state.alternativeNumber) && (
							<>
								<p>Doesn't quite look right?</p>
								<Button
									icon="arrow-right"
									onClick={() => this.convert()}
								>
									Try using different settings
								</Button>
							</>
						)}
					</>
				) : this.state.status === ConvertModalStatus.error &&
				  this.props.alternative ? (
					<>
						<Header>Done!</Header>
						<p>An error occurred during conversion</p>
						<p>Details: {this.state.error}</p>
						{(this.props.alternatives[this.props.alternative] || [])
							.length > Number(this.state.alternativeNumber) && (
							<Button
								onClick={() => this.convert()}
								variant="fill"
								color="brand"
								icon="arrow-right"
								size="medium"
							>
								Try using different settings
							</Button>
						)}
						<Button
							icon="arrow-left"
							size="medium"
							onClick={() => this.convert(true)}
						>
							Try again
						</Button>
					</>
				) : (
					<div>Invalid state</div>
				)}
			</StyledModal>
		);
	}
}
