import React from 'react';

import { useDropzone } from 'react-dropzone';
import { styledWithTheme } from '@smidyo/smidyo-reactlib-ui-atoms/style/theme';
import { Icon } from '@smidyo/smidyo-reactlib-ui-atoms/atoms/icon';
import { Card } from '@smidyo/smidyo-reactlib-ui-atoms/atoms/card';
import { Pushable } from '@smidyo/smidyo-reactlib-ui-atoms/atoms/button';

interface FileZoneProps {
	formats: string[];
	file?: File;
	onFile: (file: File) => void;
}

const DropZone = styledWithTheme(Card)`
	min-width: 13rem;
	max-width: 25rem;
	padding: 1rem;
	height: 6rem;
    display: flex;
    align-items: center;
    flex-direction: column;
	justify-content: center;
	font-size: 0.7rem;
	border: 2px solid ${(p) => p.theme.palette.brandColor};
	color: ${(p) => p.theme.palette.brandColor};
	cursor: pointer;
	margin: 1rem;
	outline: none;
	text-align: center;
`;

const LargeIcon = styledWithTheme(Icon)`
    font-size: 2rem;
    margin-top: 1rem;
`;

export const FileZone = (props: FileZoneProps) => {
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: (f) => {
			const file = f[0];
			if (!file) {
				return;
			}
			props.onFile(file);
		},
		accept: props.formats.map((f) => '.' + f),
	});

	return (
		<Pushable>
			<DropZone {...getRootProps()}>
				<input {...getInputProps()} />
				{props.file ? (
					<>
						<LargeIcon icon="file" />
						<p>{props.file.name}</p>
					</>
				) : (
					<>
						<LargeIcon icon="upload" />
						<p>Drop a file here</p>
					</>
				)}
			</DropZone>
		</Pushable>
	);
};
